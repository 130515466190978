import register from 'preact-custom-element';

export default QuantitySelector;
register(QuantitySelector, 'quantity-selector', [
  'value',
  'min',
  'max',
  'onUpdate',
]);

/**
 * A component that allows the user to select a quantity
 * It can be configured to have a minimum and maximum value
 * It takes an optional callback `onUpdate` that is called when the value is updated
 * @param props - The props
 * @returns The QuantitySelector component
 */
function QuantitySelector({
  value = 0,
  min = 0,
  max = Infinity,
  onUpdate,
}: QuantitySelectorProps) {
  // Decrease the quantity by 1 if it is greater than the minimum
  // Call the onUpdate callback if it exists
  const decrement = () => {
    if (value <= min) return;

    const newQuantity = value - 1;

    if (onUpdate) {
      onUpdate(newQuantity);
    }
  };

  // Increase the quantity by 1 if it is less than the maximum
  // Call the onUpdate callback if it exists
  const increment = () => {
    if (value >= max) return;

    const newQuantity = value + 1;

    if (onUpdate) {
      onUpdate(newQuantity);
    }
  };

  return (
    <div className="flex  h-8 w-24  justify-between rounded-md border border-gray bg-crisp-white text-center text-base font-bold text-dark-true-black">
      <button
        className="fast-transition w-8 rounded-md border-none bg-transparent text-xl outline-none hover:bg-crisp-white-hover active:bg-crisp-white-active disabled:bg-transparent disabled:text-neutral-gray"
        disabled={value <= min}
        onClick={decrement}
      >
        -
      </button>
      <span className="grow border-x border-gray text-base leading-8">
        {value}
      </span>
      <button
        className="fast-transition w-8 rounded-md border-none bg-transparent text-xl outline-none hover:bg-crisp-white-hover  active:bg-crisp-white-active disabled:bg-transparent disabled:text-neutral-gray"
        onClick={increment}
        disabled={value >= max}
      >
        +
      </button>
    </div>
  );
}

/**
 * The props for the QuantitySelector component
 * @param value - The initial value of the quantity
 * @param min - The minimum value of the quantity
 * @param max - The maximum value of the quantity
 * @param onUpdate - An optional callback that is called when the quantity is updated
 */
type QuantitySelectorProps = {
  value?: number;
  min?: number;
  max?: number;
  onUpdate?: (quantity: number) => void;
};
